import { writable, readable, derived } from "svelte/store";

interface MenuItem {
  label: string;
  path: string;
}
const items = readable<MenuItem[]>([
  { label: "Projects", path: "/work" },
  { label: "Notebook", path: "/blog" },
]);
export const currentPath = readable(window.location.pathname, (set) =>
  set(window.location.pathname),
);
export const menuItems = derived([items, currentPath], ([$items, $path]) =>
  $items.filter((i) => i.path !== $path),
);

export const menuVisible = writable(true);

export const toggleMenu = () => {
  menuVisible.update((value) => !value);
};
