<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { menuItems, menuVisible, toggleMenu } from "./menu-store";
  import Styles from "./menu.module.css";

  const appearUp = { duration: 300, y: 100 };
  let backToTopVisible = false;

  const scrollToTop = () => {
    document.querySelector("body")?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    const scrolled = document.querySelector("html")?.scrollTop || 0;
    backToTopVisible = scrolled > window.innerHeight;
  };

  onMount(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
</script>

<!--
  @component
  ### menu.svelte

  Component that displays a menu with links
  
  #### Props
  pathname - Current path
-->

{#if backToTopVisible}
  <button
    type="button"
    class={Styles.BackToTop}
    on:click={scrollToTop}
    transition:fly={appearUp}><span class="sr-only">Back to top</span></button
  >
{/if}

{#if $menuVisible}
  <nav transition:fly={appearUp} class={Styles.Nav} data-testid="menu">
    {#each $menuItems as { path, label }}
      <a href={path} on:click={toggleMenu} class={Styles.Link}>
        {label}
      </a>
    {/each}
    <button
      type="button"
      class={Styles.Toggle}
      on:click={toggleMenu}
      data-testid="hide-menu">Hide menu</button
    >
  </nav>
{:else}
  <button
    type="button"
    transition:fly={appearUp}
    class={Styles.Show}
    on:click={toggleMenu}
    data-testid="show-menu">Show menu</button
  >
{/if}
